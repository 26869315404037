import { render, staticRenderFns } from "./ErrorNotifierAlert.vue?vue&type=template&id=e783da36&"
import script from "./ErrorNotifierAlert.vue?vue&type=script&lang=ts&"
export * from "./ErrorNotifierAlert.vue?vue&type=script&lang=ts&"
import style0 from "./style.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports