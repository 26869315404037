
import {Component, Vue, Provide, Prop, Watch, Ref} from 'vue-property-decorator'
import AppTaxiLimit from '@/components/apps/AppTaxiLimit'
import ErrorNotifier from '@/components/entity/ErrorNotifier'
import {ApiService} from './services/api.service'
import mitt, {Emitter} from 'mitt'
import {GlobalEvents} from './types/mitt.types'
import ErrorNotifierService from './services/errorNotifier.service'
import {RobotWS} from './services/robotws.service'

@Component({
  components: {
    AppTaxiLimit,
    ErrorNotifier
  }
})
export default class App extends Vue {
  @Prop({type: String}) readonly token?: string

  @Ref('app') readonly coreElement!: HTMLDivElement

  // Вернуть после тестов
  // @Provide('$api') api: ApiService = process.env.NODE_ENV === 'production' ? new ApiService(process.env.VUE_APP_API_BASE_URL) : this.$api
  // Удалить перед тестами
  @Provide('$api') api: ApiService = this.$api


  @Provide('$ws') ws: RobotWS = new RobotWS()
  @Provide('$emitter') emitter: Emitter<GlobalEvents> = mitt<GlobalEvents>()
  @Provide('$error') errorNotifier = new ErrorNotifierService(this.emitter)

  outEventBus = new CustomEvent('redirect', {
    bubbles: true,
    composed: true,
    detail: {to: 'employees'}
  })

  ready: boolean = false

  mounted () {
    if (process.env.NODE_ENV === 'production') {
      this.setToken()
      this.setReadyApp()
    } else {
      this.ready = true
    }
  }

  @Watch('token')
  onTokenUpdate () {
    if (process.env.NODE_ENV === 'production') {
      this.setToken()
    }
  }

  setReadyApp () {
    if (!this.ready && this.api.isTokenExist) {
      this.ready = true
    }
  }

  setToken () {
    if (this.token) {
      this.api.setToken(this.token)
    }
    this.setReadyApp()
  }

  dispatchRedirectEvent () {
    this.coreElement.dispatchEvent(this.outEventBus)
  }
}
